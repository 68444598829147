var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DimensionsAvailableStyled', [_c('HeaderStyled', [_vm._v(" " + _vm._s(_vm.$t('titles.availableDimensions')) + " ")]), _c('SearchStyled', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchQuery,
      expression: "searchQuery"
    }],
    attrs: {
      "placeholder": _vm.$t('placeholders.searchAssetDimensions')
    },
    domProps: {
      "value": _vm.searchQuery
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.searchQuery = $event.target.value;
      }
    }
  }), _vm.isSearching ? _c('LoaderIcon') : _c('SearchIcon'), _c('transition-group', {
    staticClass: "search-filters",
    attrs: {
      "name": "fade-in-right"
    }
  }, [_vm.searchQuery ? _c('ResetSearchStyled', {
    key: 'reset_search',
    on: {
      "click": function click($event) {
        _vm.searchQuery = '';
      }
    }
  }, [_c('XSquareIcon')], 1) : _vm._e(), _vm._l(_vm.filters, function (filter) {
    return _c('SearchFilter', {
      key: filter.id,
      attrs: {
        "filter": filter
      },
      on: {
        "removeFilter": function removeFilter($event) {
          return _vm.removeFilter(filter);
        }
      }
    });
  })], 2)], 1), _c('MainStyled', {
    class: {
      'show-hint': _vm.noDimensionsRequired,
      'focus-search': _vm.focusSearch
    }
  }, [_vm.noDimensionsRequired ? _c('div', {
    staticClass: "hint"
  }, [_vm._v(" " + _vm._s(_vm.$t('messages.noDimensionsRequired')) + " ")]) : [_c('SearchResultsStyled', {
    attrs: {
      "rows": Math.min(_vm.perPage, _vm.assetDimensionsMapped.length)
    }
  }, [_c('transition-group', {
    staticClass: "search-result-list",
    attrs: {
      "name": "fade-in-down",
      "tag": "div"
    }
  }, [_vm._l(_vm.assetDimensionsMapped, function (dimension) {
    return _c('AssetDimension', {
      key: dimension.id,
      attrs: {
        "dimension": dimension
      },
      on: {
        "addDimension": function addDimension($event) {
          return _vm.$emit('addDimension', $event);
        },
        "removeDimension": function removeDimension($event) {
          return _vm.$emit('removeDimension', $event);
        }
      }
    });
  }), _vm.nothingFound ? _c('div', {
    key: 'nothing-found',
    staticClass: "nothing-found"
  }, [_vm._v(" " + _vm._s(_vm.$t('messages.searchNoResults', {
    query: _vm.searchedFor
  })) + " ")]) : _vm._e()], 2), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.totalCount > 0 && _vm.assetDimensionsMapped.length > 0 && _vm.totalCount > _vm.perPage ? _c('div', {
    staticClass: "pagination"
  }, _vm._l(_vm.pages, function (page) {
    return _c('PaginationButtonStyled', {
      key: page.id,
      attrs: {
        "disabled": page.isActive
      },
      on: {
        "click": function click($event) {
          return _vm.setPage(page);
        }
      }
    }, [_vm._v(" " + _vm._s(page.number) + " ")]);
  }), 1) : _vm._e()])], 1), _c('MostUsed', {
    staticClass: "most-used",
    attrs: {
      "dimensionsSelected": _vm.dimensionsSelected,
      "selectedWidget": _vm.selectedWidget,
      "canAddDimension": _vm.canAddDimension
    },
    on: {
      "addDimension": function addDimension($event) {
        return _vm.$emit('addDimension', $event);
      },
      "removeDimension": function removeDimension($event) {
        return _vm.$emit('removeDimension', $event);
      }
    }
  }), _c('RecentlyAdded', {
    staticClass: "recently-added",
    attrs: {
      "dimensionsSelected": _vm.dimensionsSelected,
      "selectedWidget": _vm.selectedWidget,
      "canAddDimension": _vm.canAddDimension
    },
    on: {
      "addDimension": function addDimension($event) {
        return _vm.$emit('addDimension', $event);
      },
      "removeDimension": function removeDimension($event) {
        return _vm.$emit('removeDimension', $event);
      }
    }
  })]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }