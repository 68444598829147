<template>
  <DimensionsAvailableStyled>
    <HeaderStyled>
      {{ $t('titles.availableDimensions') }}
    </HeaderStyled>
    <SearchStyled>
      <input v-model="searchQuery" :placeholder="$t('placeholders.searchAssetDimensions')" />
      <LoaderIcon v-if="isSearching" />
      <SearchIcon v-else />
      <transition-group name="fade-in-right" class="search-filters">
        <ResetSearchStyled :key="'reset_search'" v-if="searchQuery" @click="searchQuery = ''">
          <XSquareIcon />
        </ResetSearchStyled>
        <SearchFilter v-for="filter in filters" :key="filter.id" :filter="filter" @removeFilter="removeFilter(filter)" />
      </transition-group>
    </SearchStyled>
    <MainStyled :class="{ 'show-hint': noDimensionsRequired, 'focus-search': focusSearch }">
      <div class="hint" v-if="noDimensionsRequired">
        {{ $t('messages.noDimensionsRequired') }}
      </div>
      <template v-else>
        <SearchResultsStyled :rows="Math.min(perPage, assetDimensionsMapped.length)">
          <transition-group name="fade-in-down" tag="div" class="search-result-list">
            <AssetDimension
              v-for="dimension in assetDimensionsMapped"
              :key="dimension.id"
              :dimension="dimension"
              @addDimension="$emit('addDimension', $event)"
              @removeDimension="$emit('removeDimension', $event)"
            />
            <div :key="'nothing-found'" class="nothing-found" v-if="nothingFound">
              {{ $t('messages.searchNoResults', { query: searchedFor }) }}
            </div>
          </transition-group>
          <transition name="fade">
            <div class="pagination" v-if="totalCount > 0 && assetDimensionsMapped.length > 0 && totalCount > perPage">
              <PaginationButtonStyled v-for="page in pages" :key="page.id" :disabled="page.isActive" @click="setPage(page)">
                {{ page.number }}
              </PaginationButtonStyled>
            </div>
          </transition>
        </SearchResultsStyled>
        <MostUsed
          class="most-used"
          :dimensionsSelected="dimensionsSelected"
          :selectedWidget="selectedWidget"
          :canAddDimension="canAddDimension"
          @addDimension="$emit('addDimension', $event)"
          @removeDimension="$emit('removeDimension', $event)"
        />
        <RecentlyAdded
          class="recently-added"
          :dimensionsSelected="dimensionsSelected"
          :selectedWidget="selectedWidget"
          :canAddDimension="canAddDimension"
          @addDimension="$emit('addDimension', $event)"
          @removeDimension="$emit('removeDimension', $event)"
        />
      </template>
    </MainStyled>
  </DimensionsAvailableStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import { flexCenter, buttonReset } from '@styles/mixins'

import chroma from 'chroma-js'

import { debounce } from 'vue-debounce'

import { SearchIcon, LoaderIcon, XSquareIcon } from 'vue-feather-icons'

import MostUsed from './DimensionsAvailable/MostUsed'
import RecentlyAdded from './DimensionsAvailable/RecentlyAdded'
import AssetDimension from './AssetDimension'
import SearchFilter from '@/components/Misc/SearchFilter'

import { ButtonRound as PaginationButtonStyled } from '@styles/buttons'

import ASSET_DIMENSIONS_QUERY from '#/graphql/assetDimensions/assetDimensions.gql'
import ASSET_DIMENSIONS_COUNT_QUERY from '#/graphql/assetDimensions/assetDimensionsCount.gql'

const DimensionsAvailableStyled = styled('div')`
  grid-area: dimensions-available;
  display: grid;
  margin: 0.5rem;
  overflow-y: auto;
  background: ${p => p.theme.colors.solidBG};
  box-shadow: ${p => p.theme.colors.widgetShadow};
  border-radius: 0.5rem;
  grid-template-rows: 2rem 3rem 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header'
    'search'
    'main';
`

const MainStyled = styled('div')`
  display: grid;
  overflow: auto;
  position: relative;
  padding: 0.5rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1rem 1fr 1fr;
  grid-template-areas:
    'search-results'
    'most-used'
    'recently-added';

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1rem 1fr;
    grid-template-areas:
      'search-results search-results'
      'most-used recently-added';
  }

  &.focus-search {
    grid-template-rows: 1fr 0 0;
    .most-used,
    .recently-added {
      display: none;
    }

    @media (min-width: 768px) {
      grid-template-rows: 1fr 0;
    }
  }

  &.show-hint {
    grid-template-areas: 'hint';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .hint {
    ${flexCenter}
  }

  .fade-leave-active {
    position: absolute;
    display: none;
  }
`

const HeaderStyled = styled('div')`
  ${flexCenter}
  grid-area: header;
  color: ${p => p.theme.colors.primary};
`

const SearchStyled = styled('div')`
  ${flexCenter}
  grid-area: search;
  position: relative;
  font-weight: 100;
  display: flex;
  overflow: hidden;
  border-top: 1px solid ${p => (p.theme.isDark ? p.theme.colors.talpaGreyDarker : p.theme.colors.lightGrey)};
  border-bottom: 1px solid ${p => (p.theme.isDark ? p.theme.colors.talpaGreyDarker : p.theme.colors.lightGrey)};
  input {
    flex-grow: 1;
    padding-left: 2rem;
    margin: 0.25rem;
    height: 2rem;
    border: none;
    color: ${p => (p.theme.isDark ? p.theme.colors.navFontNormal : p.theme.colors.primary)};
    background: ${p => chroma(p.theme.colors.navFontNormal).alpha(0.2).css()};
    &:focus {
      outline: none;
      border: none;
    }
    ::placeholder {
      color: ${p => chroma(p.theme.colors.navFontNormal).alpha(0.7).css()};
    }
  }
  .feather-search,
  .feather-loader {
    position: absolute;
    left: 0.5rem;
    color: ${p => chroma(p.theme.colors.navFontNormal).alpha(0.7).css()};

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    &.feather-loader {
      animation: spin 1s linear infinite;
    }
  }
  .search-filters {
    ${flexCenter}
    position: absolute;
    right: 0.5rem;
  }
`

const ResetSearchStyled = styled('button')`
  ${buttonReset}
  ${flexCenter}
  margin-right: 1rem;
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    color: ${({ theme }) => theme.colors.primaryLighter};
  }
`

const SearchResultsStyled = styled('div')`
  grid-area: search-results;
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;

  .search-result-list {
    position: relative;
    overflow: auto;
    .fade-in-down-leave-active {
      position: absolute;
    }
  }
  .pagination {
    display: grid;
    padding: 0.25rem 0;
    width: 100%;
    max-width: 80vw;
    grid-auto-rows: 2rem;
    grid-template-columns: repeat(8, 2rem);
    justify-content: center;
    justify-items: center;
    align-items: center;
    @media (min-width: 768px) {
      grid-template-columns: none;
      grid-auto-columns: 2rem;
      grid-auto-flow: column;
    }
    background: ${p => (p.theme.isDark ? p.theme.colors.talpaGrey : p.theme.colors.white)};
    button {
      ${flexCenter}
      padding: 0;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`

export default {
  props: {
    dimensionsSelected: {
      type: Array,
      required: true,
    },
    selectedWidget: {
      type: Object,
    },
  },
  components: {
    DimensionsAvailableStyled,
    HeaderStyled,
    SearchStyled,
    MainStyled,
    SearchResultsStyled,
    MostUsed,
    RecentlyAdded,
    AssetDimension,
    LoaderIcon,
    SearchIcon,
    XSquareIcon,
    SearchFilter,
    PaginationButtonStyled,
    ResetSearchStyled,
  },
  data() {
    return {
      searchQuery: '',
      focusSearch: false,
      willExecuteSearch: false,
      filtersAdded: [],
      assetDimensions: [],
      assetDimensionsWhere: null,
      totalCount: 0,
      currentPage: 0,
      perPage: 6,
    }
  },
  created() {
    this.debouncedSearch()
  },
  computed: {
    pages() {
      const pageCount = Math.ceil(this.totalCount / this.perPage)
      const pages = []
      for (let i = 0; i < pageCount; i++) {
        pages.push({
          id: 'page_' + i,
          number: i + 1,
          value: i,
          isActive: this.currentPage === i,
        })
      }
      return pages
    },
    searchedFor() {
      let qry = ''
      const ORs = get(this.assetDimensionsWhere, 'OR', [])
      if (ORs.length > 0) {
        qry += `'${ORs[ORs.length - 1].name_contains}'`
      } else {
        if (this.assetDimensionsWhere?.nameTranslations_some?.text_contains) {
          qry += `'${this.assetDimensionsWhere?.nameTranslations_some?.text_contains}'`
        }
      }
      return qry
    },
    isSearching() {
      return this.$apollo.loading
    },
    nothingFound() {
      return !this.isSearching && this.assetDimensionsWhere && this.assetDimensions.length === 0
    },
    filters() {
      const filters = []
      if (this.selectedWidget) {
        filters.push({
          id: 'widget_type',
          type: this.$tc('widget', 1),
          value: this.selectedWidget.name,
          label: this.$t('widgetTypes.' + this.selectedWidget.name),
        })
      }
      return filters
    },
    canAddDimension() {
      return this.selectedWidget ? this.selectedWidget.maxDimensions > this.dimensionsSelected.length : true
    },
    noDimensionsRequired() {
      return get(this.selectedWidget, 'maxDimensions') === 0
    },
    assetDimensionsMapped() {
      const mapped = this.assetDimensions
        .map(ad => {
          const selectedWidgetIsCompatible = this.selectedWidget ? ad.compatibleWidgets.find(f => f.id === this.selectedWidget.id) : true
          const isEnabled = this.canAddDimension && selectedWidgetIsCompatible
          return {
            ...ad,
            isSelected: this.dimensionsSelected.find(ds => ds.id === ad.id),
            isEnabled,
          }
        })
        .filter(dimension => dimension.compatibleWidgets.length > 0)
      return mapped
    },
  },
  watch: {
    searchQuery: {
      handler(qry) {
        this.willExecuteSearch = true
        this.debouncedSearch(qry)
      },
    },
    filters: {
      handler() {
        this.willExecuteSearch = true
        this.debouncedSearch()
      },
      immediate: true,
    },
  },
  methods: {
    debouncedSearch: debounce(function () {
      if (this.searchQuery.length > 0 || this.filters.length > 0) {
        const where = {}
        this.currentPage = 0
        if (this.searchQuery.length) {
          where.nameTranslations_some = {
            text_contains: this.searchQuery,
          }
        }
        const widgetFilter = this.filters.find(f => f.id === 'widget_type')
        if (widgetFilter) {
          where.compatibleWidgets_some = {
            name: widgetFilter.value,
          }
        }
        this.assetDimensionsWhere = where
        this.focusSearch = true
      } else {
        this.assetDimensions = []
        this.assetDimensionsWhere = null
        this.focusSearch = false
      }
      this.willExecuteSearch = false
    }, 300),
    setPage(page) {
      const el = this.$el
      if (el) {
        const list = el.getElementsByClassName('focus-search')
        if (list[0]) {
          list[0].scrollTo({ top: 0 })
        }
      }
      this.currentPage = page.value
    },
    removeFilter(filter) {
      if (filter.id === 'widget_type') {
        this.$emit('unselectWidgetType')
      }
    },
  },
  apollo: {
    assetDimensions: {
      query: ASSET_DIMENSIONS_QUERY,
      variables() {
        return {
          first: this.perPage,
          skip: this.currentPage * this.perPage,
          where: this.assetDimensionsWhere,
        }
      },
      skip() {
        return !this.assetDimensionsWhere
      },
    },
    assetDimensionsCount: {
      query: ASSET_DIMENSIONS_COUNT_QUERY,
      variables() {
        return {
          where: this.assetDimensionsWhere,
        }
      },
      skip() {
        return !this.assetDimensionsWhere
      },
      update(res) {
        this.totalCount = get(res, 'assetDimensionsConnection.aggregate.count', 0)
      },
    },
  },
}
</script>
