var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SearchFilterStyled', [_c('div', {
    staticClass: "type"
  }, [_vm._v(_vm._s(_vm.filter.type) + ":")]), _c('div', {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.filter.label) + " ")]), _c('button', {
    staticClass: "remove",
    on: {
      "click": function click($event) {
        return _vm.$emit('removeFilter');
      }
    }
  }, [_c('XIcon')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }