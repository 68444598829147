<template>
  <SearchFilterStyled>
    <div class="type">{{ filter.type }}:</div>
    <div class="label">
      {{ filter.label }}
    </div>
    <button class="remove" @click="$emit('removeFilter')">
      <XIcon />
    </button>
  </SearchFilterStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { flexCenter, buttonReset } from '@styles/mixins'

import { XIcon } from 'vue-feather-icons'

const SearchFilterStyled = styled('div')`
  ${flexCenter}
  width: 9.5rem;
  color: ${p => p.theme.colors.white};
  background: ${p => (p.theme.isDark ? p.theme.colors.primaryDarker : p.theme.colors.primary)};
  font-size: 0.7rem;
  height: 1.5rem;
  > div {
    width: 4rem;
    ${flexCenter}
  }
  .label {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
  }
  .remove {
    ${buttonReset}
    ${flexCenter}
    padding: 0;
    width: 1.5rem;
    color: ${p => p.theme.colors.white};

    &:hover {
      color: ${p => (p.theme.isDark ? p.theme.colors.primaryLighter : p.theme.colors.secondary)};
    }

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  components: {
    SearchFilterStyled,
    XIcon,
  },
}
</script>
